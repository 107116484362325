import React, { useState } from "react";
import { PlusCircle, MinusCircle } from "react-feather";

interface SectionInfo {
    Title: string;
    children: React.ReactNode;
}

const SectionExpand = ({ Title, children } : SectionInfo) => {
    const [open, setOpen] = useState(false);

    return (
        <div className="tw-w-full tw-mb-8">
            <div className="tw-flex tw-w-full tw-shadow-md tw-justify-between tw-items-center tw-rounded-lg tw-p-6 tw-border-2 tw-cursor-pointer" onClick={() => setOpen(!open)}>
                <div>
                    <h2 className="tw-text-lg tw-font-bold">
                        {Title}
                    </h2>
                </div>
                <div>
                    {
                        open ?
                        <MinusCircle className="tw-text-safari-green" size={30} />
                        :
                        <PlusCircle className="tw-text-safari-green" size={30} />
                    }
                </div>
            </div>
            {
                open ?
                <div className="tw-shadow tw-mt-4 tw-p-8 tw-border-2 tw-rounded-lg">
                    {children}
                </div>
                :
                null
            }
        </div>
    )
}

export {
    SectionExpand,
    SectionInfo,
}