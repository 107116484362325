import React, { useState } from "react";
import { Navbar } from "../components/Navbar";
import { SEO } from "../components/SEOComponent";
import { SectionExpand, SectionInfo } from "../components/SectionExpand";
import { Register } from "../components/Register";
import Footer from "../components/Footer";

const FAQSection : SectionInfo[] = [
    {
        Title: "How do I become a CloudSafari tour operator?",
        children: (
            <div>
                <p className="tw-font-medium">
                    To become a tour operator on CloudSafari, simple follow the steps below:
                </p>
                <br />
                <p>
                    1. Send us an email with your basic company information, and contact details.
                </p>
                <br />
                <p>
                    2. One of our team members will contact you to schedule an interview. The interview will consist of questions for us to understand how you conduct your business.
                </p>
                <br />
                <p>
                    3. If your interview is successful, our team will verify your company on CloudSafari and you can proceed to onboard on our platform as instructed by one of our team members.
                </p>
            </div>
        )
    },
    {
        Title: "How much do you charge & what is your fee structure like?",
        children: (
            <div>
                <p>
                    We don't charge any fees to join our platform as a tour operator ... yes you heard that right, there are NO fees to being listed, and there are NO fees for an inquiry or quote request that you receive.
                </p>
                <br />
                <p>
                    We make our money and charge a fee on every <span className="tw-font-bold">confirmed</span> trip that a customer makes with you. Payments are processed through our platform, so when a customer pays we take our fee and send the rest of the money to you.
                </p>
                <br />
                <p>
                    For more information on this transaction fee or how payments are processed, send us an email at <a href="mailto:hello@mycloudsafari.com" className="tw-text-blue-500">hello@mycloudsafari.com</a>.
                </p>
            </div>
        )
    },
    {
        Title: "How many tour operators do you allow onto your platform?",
        children: (
            <div>
                <p>
                    We're currently taking 10 operators per country. This is so that each operator gets a fair chance at the inquiries, and so that we can ensure our customers are connecting with qualified operators.
                </p>
                <br />
            </div>
        )
    },
    {
        Title: "What is the interview process like?",
        children: (
            <div>
                <p>
                    The interview process takes about 30 minutes with a series of questions that are standard to each tour operator. Tour operators are evaluated on a number of metrics, but also how well you communicate throughout the process - don't worry we don't bite :)
                </p>
            </div>
        )
    },
]

interface CompanyCardInfo {
    ImageURL: string;
    ImageAlt: string;
    Name: string;
    CompanyName: string;
}

const CompanyInformation : CompanyCardInfo[] = [
    {
        ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/tour-operator%2Fjane-ulinda-safaris.jpeg?alt=media&token=00306202-081a-4807-af29-b63700934686",
        ImageAlt: "Jane Bettenay taking a picture on safari",
        Name: "Jane Bettenay",
        CompanyName: "Ulinda Safari Trails",
    },
    {
        ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/tour-operator%2Fcliff-savannah-tours.jpeg?alt=media&token=0ecf13e9-c1d7-489e-98bc-981699d1c194",
        ImageAlt: "Cliff D'Souza standing next to a Savannah Tours 4x4 Vehicle",
        Name: "Cliff D'Souza",
        CompanyName: "Savannah Tours",
    },
    {
        ImageURL: "https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/tour-operator%2Fextremely-wild-safaris.jpeg?alt=media&token=fc8dc88a-1471-4c64-8725-f6a769ffc9ce",
        ImageAlt: "Isa in the Okavango Delta",
        Name: "Isa",
        CompanyName: "Extremely Wild Safaris",
    },
]

const CompanyCard = ({ ImageURL, ImageAlt, Name, CompanyName } : CompanyCardInfo ) => {
    return (
        <div className="tw-w-full tw-text-left tw-h-full">
            <img
                src={ImageURL}
                alt={ImageAlt}
                className="tw-w-full tw-h-[40vh] tw-object-cover tw-rounded-lg"
            />
            <h3 className="tw-font-bold tw-text-2xl tw-mt-4">
                {Name}
            </h3>
            <h5 className="tw-mt-2 tw-text-lg">
                {CompanyName}
            </h5>
        </div>
    )
}

const JoinAsOperator = () => {
    const [open, setOpen] = useState(false);

    return (
        <div>
            <SEO 
                Title="CloudSafari | Become a CloudSafari Tour Operator"
                Description="Sign up today to launch our process so you can become a trusted and qualified tour operator for Africa!"
            />

            <Register open={open} setOpen={setOpen} operatorLogin={true} signUpView={true} stayOnPage={false} />

            {/* Banner Image */}
            <div className="tw-flex tw-flex-row tw-flex-wrap tw-justify-center tw-items-stretch tw-w-full tw-pt-12 tw-pb-4 lg:tw-py-12">
                <div className="tw-hidden lg:tw-block lg:tw-flex tw-min-h-[40vh] tw-rounded-tl-lg lg:tw-rounded-bl-lg tw-rounded-tr-lg lg:tw-rounded-tr-none tw-w-full lg:tw-w-1/3 tw-p-12 tw-bg-black tw-flex-col tw-text-center tw-justify-center tw-items-center">
                    <h1 className="tw-text-white tw-text-3xl tw-mb-4">
                        Open your Experiences to Tourists
                    </h1>
                    <button 
                    onClick={() => setOpen(true)}
                    className="tw-bg-safari-green tw-min-w-[300px] tw-px-6 tw-py-2 tw-rounded-xl tw-text-white">
                        Join as a Tour Operator
                    </button>
                </div>
                <div className="tw-w-full lg:tw-w-2/3 tw-bg-black tw-rounded-lg lg:tw-rounded-br-lg lg:tw-rounded-tl-none lg:tw-rounded-bl-none lg:tw-rounded-tr-lg">
                <img 
                    className="tw-h-70vh tw-w-full tw-object-cover tw-opacity-70 tw-rounded-lg lg:tw-rounded-none"
                    src="https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/tour-operator%2Ftsa-jordan-iris.jpeg?alt=media&token=495c51aa-f1ea-4d7a-8714-790e420c1be5"
                />
                </div>
                <div className="tw-w-full lg:tw-hidden">
                    <h1 className="tw-text-3xl tw-mb-4 tw-mt-4">
                        Open your Experiences to Tourists
                    </h1>
                    <button 
                    onClick={() => setOpen(true)}
                    className="tw-bg-safari-green tw-min-w-[300px] tw-px-6 tw-py-2 tw-rounded-xl tw-text-white">
                        Join as a Tour Operator
                    </button>
                </div>
            </div>

            {/* Good Company */}
            <div className="tw-w-full tw-py-20 lg:tw-py-24 tw-flex tw-flex-col tw-items-center tw-justify-center tw-text-center">
                <h1 className="tw-text-xl lg:tw-text-3xl">
                    You're in Good Company
                </h1>
                <div className="tw-w-full tw-flex tw-flex-row tw-flex-wrap tw-mt-4">
                    {
                        CompanyInformation.map((info) => (
                            <div className="tw-w-full lg:tw-w-1/3 tw-pb-8 lg:tw-p-4">
                                <CompanyCard 
                                    ImageURL={info.ImageURL}
                                    ImageAlt={info.ImageAlt}
                                    Name={info.Name}
                                    CompanyName={info.CompanyName} 
                                />
                            </div>
                        ))
                    }
                </div>
            </div>

            {/* FAQ */}
            <div id="test" className="tw-w-full tw-py-0 tw-pb-12 lg:tw-py-24 tw-flex tw-flex-col tw-items-center tw-justify-center">
                <h1 className="tw-text-xl lg:tw-text-3xl tw-text-center">
                    Frequently Asked Questions
                </h1>
                <div className="tw-w-full tw-mt-8">
                    {
                        FAQSection.map((faq) => (
                            <SectionExpand
                                Title={faq.Title}
                            >
                                {faq.children}
                            </SectionExpand>
                        ))
                    }
                </div>
            </div>

            {/* Other Questions */}
            <div className="tw-bg-black tw-mb-12 lg:tw-mb-20 tw-rounded-lg tw-relative tw-text-center">
                <img 
                    src="https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/activities%2Flina-loos-04-C1NZk1hE-unsplash.jpg?alt=media&token=6c39ed52-a8fc-4a3e-9915-653d30ae0455"
                    className="tw-rounded-lg tw-h-[60vh] tw-object-cover tw-w-full tw-opacity-40"
                />
                <div className="tw-absolute tw-top-[45%] tw-w-full tw-flex tw-justify-center tw-flex-col">
                    <h1 className="tw-text-white tw-mb-4 tw-text-xl lg:tw-text-4xl">
                    Have another question? Ask our team.
                    </h1>
                    <a href="mailto:hello@mycloudsafari.com">
                        <button className="tw-bg-safari-green tw-min-w-[300px] tw-px-6 tw-py-2 tw-rounded-xl tw-text-white">
                            Contact Us
                        </button>
                    </a>
                </div>
            </div>

            {/* CTA 2 */}
            <div className="tw-flex tw-pb-12 tw-flex-row tw-flex-wrap tw-justify-center tw-items-stretch tw-w-full tw-pt-12 tw-pb-4 lg:tw-py-12">
                <div className="tw-w-full lg:tw-w-2/3 tw-bg-black tw-rounded-lg lg:tw-rounded-bl-lg lg:tw-rounded-tr-none lg:tw-rounded-br-none lg:tw-rounded-tl-lg">
                <img 
                    className="tw-h-70vh tw-w-full tw-object-cover tw-opacity-50 tw-rounded-lg tw-rounded-br-none tw-rounded-bl-none lg:tw-rounded-bl-lg lg:tw-rounded-none"
                    src="https://firebasestorage.googleapis.com/v0/b/cloudsafari-dev.appspot.com/o/tour-operator%2Fkalahari-skies-botswana.jpeg?alt=media&token=3d98217f-82c5-4255-89e6-9df57bb7aa07"
                />
                </div>
                <div className="lg:tw-flex tw-min-h-[40vh] tw-rounded-lg tw-rounded-tr-none tw-rounded-tl-none lg:tw-rounded-tr-lg lg:tw-rounded-bl-none lg:tw-rounded-tl-none tw-w-full lg:tw-w-1/3 tw-p-12 tw-bg-black tw-flex-col tw-text-center tw-justify-center tw-items-center">
                    <h1 className="tw-text-white tw-text-3xl tw-mb-4">
                        Apply to CloudSafari to become our Trusted Tour Operator
                    </h1>
                    <button 
                    onClick={() => setOpen(true)}
                    className="tw-bg-safari-green tw-min-w-[300px] tw-px-6 tw-py-2 tw-rounded-xl tw-text-white">
                        Join as a Tour Operator
                    </button>
                </div>
            </div>
            
        </div>
    )
};

export default JoinAsOperator;